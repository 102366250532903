import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'semantic-ui-react';
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  PlaybackRateMenuButton,
  Player,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react';

import Header from '../components/header';
import Layout from '../components/layout';
// import KetButton from '../components/ket-button';
import SEO from '../components/seo';

import './index.css';

import posterImage from '../images/poster-image-home.png';

const Index = () => (
  <Layout>
    <SEO title="Kinetic Energy Tools" />
    <Helmet>
      <body className="index-page-body" />
    </Helmet>
    <Header />
    <Container className="index-page" text>
      <div class="index-page-video-headline">A New Brass Catcher Alternative</div>
      <div class="index-page-video-sub-headline">portable, powerful, and non-intrusive</div>
      <Player poster={posterImage} autoPlay loop muted playsInline>
        <BigPlayButton position="center" />
        {/* <source src="./videos/home-how-to-demo.mp4" /> */}
        <source src="../videos/brass-deflector-video.mp4" />
        <ControlBar>
          <VolumeMenuButton />
          <CurrentTimeDisplay disabled />
          <TimeDivider disabled />
          <PlaybackRateMenuButton disabled />
        </ControlBar>
      </Player>
      <div className="index-page-copy">
        The KET Brass-Deflector is a new, patent-pending brass catcher alternative. Built tough,
        lightweight, and heat resistant, our products allow law enforcement, shooters, and reloaders
        to keep spent brass close for easy cleanup, without weighing down your firearm or
        interfering with moving parts and optics. The simple, snap-on installation takes only a
        moment&mdash;with no mounts required&mdash;directly on your AR scope or Picatinny rail.
      </div>
      {/* <div className="index-page-button">
        <a
          href="https://www.amazon.com/KET-Brass-Deflector-Alternative-Included/dp/B07VQ2TG2J/ref=sr_1_30?dchild=1&keywords=brass+catcher&qid=1576515727&s=sporting-goods&sr=1-30&th=1"
          rel="noreferrer noopener"
          target="_blank"
        >
          <KetButton>See Price on Amazon</KetButton>
        </a>
      </div> */}
      <div className="index-page-email">
        <a href="mailto:sales@ket-us.com">sales@ket-us.com</a>
      </div>
    </Container>
  </Layout>
);

export default Index;
